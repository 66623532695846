// Generated by Framer (92d6359)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {Dxyd36GEa: {hover: true}};

const serializationHash = "framer-7ri9m"

const variantClassNames = {Dxyd36GEa: "framer-v-bxglfb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "Dxyd36GEa", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-bxglfb", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Dxyd36GEa"} ref={ref ?? ref1} style={{backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 5000, borderBottomRightRadius: 5000, borderTopLeftRadius: 5000, borderTopRightRadius: 5000, ...style}} variants={{"Dxyd36GEa-hover": {backgroundColor: "var(--token-32befe6c-dabb-43a8-9f4a-c0f79825e61d, rgb(130, 255, 31))"}}} {...addPropertyOverrides({"Dxyd36GEa-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-16crgo"} layoutDependency={layoutDependency} layoutId={"MUpsiTeiV"}><SVG className={"framer-19vxjff"} data-framer-name={"Graphic"} fill={"var(--token-dd95d322-8000-4206-8f77-378db76ee406, rgb(255, 255, 255)) /* {\"name\":\"White\"} */"} intrinsicHeight={24} intrinsicWidth={24} layoutDependency={layoutDependency} layoutId={"ErAn6piJl"} svg={"<svg stroke=\"currentColor\" fill=\"none\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" height=\"24\" width=\"24\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M5 12h14m-7-7 7 7-7 7\"/></svg>"} withExternalLayout/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7ri9m.framer-uutf61, .framer-7ri9m .framer-uutf61 { display: block; }", ".framer-7ri9m.framer-bxglfb { cursor: pointer; height: 100px; overflow: hidden; position: relative; width: 100px; will-change: var(--framer-will-change-override, transform); }", ".framer-7ri9m .framer-16crgo { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; left: 0px; overflow: visible; padding: 0px; position: absolute; top: 35px; width: 100%; }", ".framer-7ri9m .framer-19vxjff { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 30px); position: relative; width: 30px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-7ri9m .framer-16crgo { gap: 0px; } .framer-7ri9m .framer-16crgo > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-7ri9m .framer-16crgo > :first-child { margin-left: 0px; } .framer-7ri9m .framer-16crgo > :last-child { margin-right: 0px; } }", ".framer-7ri9m.framer-v-bxglfb.hover.framer-bxglfb { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 100px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 100
 * @framerIntrinsicWidth 100
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"m6gSEu2qu":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerHt19ANbsT: React.ComponentType<Props> = withCSS(Component, css, "framer-7ri9m") as typeof Component;
export default FramerHt19ANbsT;

FramerHt19ANbsT.displayName = "Hover Click";

FramerHt19ANbsT.defaultProps = {height: 100, width: 100};

addFonts(FramerHt19ANbsT, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})